.chat-container {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full viewport height */
}

.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #f5f5f5; /* Optional: Header background color */
}

.chat-box {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  background-color: #ffffff; /* Optional: Chat background color */
}

.message {
  margin-bottom: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  max-width: 80%;
}

.message.user {
  align-self: flex-end;
  background-color: #dcf8c6; /* User message color */
}

.message.assistant {
  align-self: flex-start;
  background-color: #f1f0f0; /* Assistant message color */
}

.input-box {
  display: flex;
  align-items: flex-end;
  padding: 0.5rem;
  gap: 0.5rem; /* Space between the textarea and button */
  background-color: #f5f5f5; /* Optional: Input box background color */
}

.text-area {
  flex: 1;
  resize: none; /* Let JS handle resizing */
  overflow-y: auto;
  line-height: 1.5rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  max-height: 200px; /* Approx 10 lines */
  min-height: 3rem; /* Approx 2 lines */
  font-size: 1rem;
}

.send-button {
  height: 3rem; /* Match the initial height of the textarea */
  padding: 0 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.send-button:disabled {
  background-color: #a0a0a0;
  cursor: not-allowed;
}