/* App.css */
.App {
  text-align: center;
  background-color: #2f2f2f;
  color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.App-header {
  margin: 0 auto;
}

.btn {
  padding: 10px 20px;
  background-color: #0064c8;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.btn:hover {
  background-color: #0359b6;
}